import { render, staticRenderFns } from "./MemberShopGiveawayPreviewBlock.vue?vue&type=template&id=f8dfe314&scoped=true"
import script from "./MemberShopGiveawayPreviewBlock.vue?vue&type=script&lang=js"
export * from "./MemberShopGiveawayPreviewBlock.vue?vue&type=script&lang=js"
import style0 from "./MemberShopGiveawayPreviewBlock.vue?vue&type=style&index=0&id=f8dfe314&prod&scoped=true&lang=postcss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f8dfe314",
  null
  
)

export default component.exports