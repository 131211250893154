<template>
  <div class="member-shop-giveaway-info-edit-block">
    <p class="card-title">基本資訊</p>
    <el-form ref="formRef" label-position="top" :model="formData" :rules="formRules">
      <el-form-item label="活動名稱" prop="name">
        <el-input v-model="formData.name" placeholder="請輸入活動名稱" />
      </el-form-item>
      <el-form-item label="活動期間" prop="time">
        <el-date-picker
          v-model="formData.time"
          type="daterange"
          format="yyyy-MM-dd"
          range-separator="至"
          start-placeholder="開始日期"
          end-placeholder="結束日期"
        />
      </el-form-item>
      <el-form-item label="優惠模式" prop="mode">
        <el-select v-model="formData.mode">
          <el-option
            value="quantity"
            label="指定商品滿件送件"
          />
        </el-select>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { noEmptyRules } from '@/validation'
import { defineComponent, ref, reactive, watch, onMounted, computed } from 'vue'
import eventBus from '@/utils/eventBus'
import formUtils from '@/utils/form'

export default defineComponent({
  name: 'MemberShopGiveawayInfoEditBlock',
  props: ['form', 'giveawayData', 'formsPass'],
  emits: ['update', 'update:formPass'],
  setup (props, { emit }) {
    const syncFormPass = computed({
      get () {
        return props.formPass
      },
      set (data) {
        emit('update:formPass', data)
      },
    })

    eventBus.on('validate-info', async (e) => {
      let pass
      if (formRef.value) pass = await formUtils.checkForm(formRef.value)
      syncFormPass.value = pass
    })

    const formRef = ref()
    const formData = reactive({
      name: null,
      time: null,
      mode: 'quantity',
    })
    const formRules = {
      name: [noEmptyRules()],
      time: [noEmptyRules()],
      mode: [noEmptyRules()],
    }

    watch(formData, () => {
      emit('update', { ...formData })
    })

    onMounted(() => {
      const data = props.giveawayData
      if (!data) return
      formData.name = data.name
      formData.time = [data.startAt, data.endAt]
      formData.mode = 'quantity'
    })

    return { formData, formRef, formRules }
  },
})
</script>

<style scoped lang="postcss">

</style>
