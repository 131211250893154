<template>
  <div class="member-shop-giveaway-products-preview-block">
    <p class="card-title">指定商品</p>
    <div v-for="(product, index) in get(giveawayData, 'TargetProducts')" :key="product.id">
      <p>{{ index + 1 }}. {{ product.name }}</p>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { get } from 'lodash'

export default defineComponent({
  name: 'MemberShopGiveawayProductsPreviewBlock',
  props: {
    giveawayData: { type: Object, default: () => ({}) },
  },
  setup (props) {
    return { get }
  },
})
</script>

<style lang="postcss" scoped>

</style>
