<template>
  <main class="member-shop-giveaway-edit">
    <PageTitle
      :title="giveawayId? '編輯贈品活動': '新增贈品活動'"
      icon="chevron_left"
      hideBtn
      @iconClick="$router.push({
        name: 'MemberShopGiveaway'
      })"
    />
    <div v-loading="loading" />
    <template v-if="!loading">
      <MemberShopGiveawayInfoEditBlock
        class="card-container card-container-padding"
        :giveawayData="giveawayData"
        :formPass.sync="formsPass.info"
        @update="updateForm('info', $event)"
      />

      <MemberShopGiveawayProductsPreviewBlock
        v-show="giveawayId"
        class="card-container card-container-padding"
        :giveawayData="giveawayData"
      />
      <MemberShopGiveawayProductsEditBlock
        v-if="!giveawayId"
        class="card-container card-container-padding"
        :storeProducts="storeProducts"
        :formPass.sync="formsPass.products"
        @update="updateForm('products', $event)"
      />

      <MemberShopGiveawayPromoConditionsBlock
        v-for="(form, id, index) in conditionForms"
        v-show="!giveawayId"
        :key="`form-${id}`"
        :formPass.sync="conditionFormsPass[id]"
        :formIndex="index"
        :storeProducts="storeProducts"
        :giveawayData="giveawayData"
        class="card-container card-container-padding"
        @update="updateConditionForm(id, $event)"
        @remove="removeConditionBlock(id)"
      />
      <el-button
        v-if="!giveawayId"
        :class="Object.keys(conditionForms).length === 5 ? '' : `text-primary-100 underline`"
        type="text"
        :disabled="Object.keys(conditionForms).length === 5"
        @click="addCondition"
      >
        ＋新增優惠條件組合（至多五個條件）
      </el-button>

      <MemberShopGiveawayPreviewBlock
        class="card-container card-container-padding"
        :storeProducts="storeProducts"
        :postData="compactPostData()"
      />
    </template>
    <PageFixedFooter
      :confirmLoading="submitting"
      @confirm="onSubmit"
      @cancel="$router.push({
        name: 'MemberShopGiveaway'
      })"
    />
  </main>
</template>

<script>
import { computed, defineComponent, onBeforeUnmount, reactive, watch, set, del, ref, onMounted } from 'vue'
import PageTitle from '@/components/Title/PageTitle.vue'
import MemberShopGiveawayInfoEditBlock from './components/MemberShopGiveawayInfoEditBlock.vue'
import MemberShopGiveawayProductsEditBlock from './components/MemberShopGiveawayProductsEditBlock.vue'
import MemberShopGiveawayPromoConditionsBlock from './components/MemberShopGiveawayPromoConditionsBlock.vue'
import MemberShopGiveawayPreviewBlock from './components/MemberShopGiveawayPreviewBlock.vue'
import MemberShopGiveawayProductsPreviewBlock from './components/MemberShopGiveawayProductsPreviewBlock.vue'
import { GetMemberShopProduct, GetMemberShopProductCount, CreateMemberShopGiveaway, UpdateMemberShopGiveaway, FindMemberShopGiveaway } from '@/api/memberShop'
import eventBus from '@/utils/eventBus'
import { find, get, values, map } from 'lodash'
import { useRoute, useRouter } from 'vue-router/composables'
import { nanoid } from 'nanoid'
import store from '@/store'
import { getAllDataFromApi, timeout } from '@/utils/helper'

export default defineComponent({
  name: 'MemberShopGiveawayEdit',
  components: { PageTitle, MemberShopGiveawayInfoEditBlock, MemberShopGiveawayProductsEditBlock, MemberShopGiveawayPromoConditionsBlock, MemberShopGiveawayPreviewBlock, MemberShopGiveawayProductsPreviewBlock },
  setup (props) {
    const shopId = computed(() => store.getters.shop)
    const route = useRoute()
    const router = useRouter()
    const storeProducts = ref([])
    const giveawayId = computed(() => route.params.id)
    const giveawayData = ref(null)
    const loading = ref(false)
    const submitting = ref(false)
    // 這裡將所有資料進行整理與清理，組成最後要送出 post/put 的資料格式
    const compactPostData = () => {
      const infoForm = get(forms, 'info')
      const productsForm = get(forms, 'products')
      const conditions = []

      for (const key in conditionForms) {
        conditions.push(conditionForms[key])
      }

      return {
        name: get(infoForm, 'name'),
        startAt: get(infoForm, 'time[0]'),
        endAt: get(infoForm, 'time[1]'),
        targetProductIds: productsForm,
        conditions,
      }
    }

    const getProductCount = async () => {
      const [res, err] = await GetMemberShopProductCount({ shopId: shopId.value })
      if (err) throw err
      return res.count
    }
    // 取得所有商城商品
    const getAllStoreProducts = async () => {
      let max
      try {
        max = await getProductCount()
      } catch (error) {
        window.$message.error(error)
        return
      }
      const config = {
        shopId: shopId.value,
        start: 0,
        limit: 100,
      }
      const [res, err] = await getAllDataFromApi(
        max,
        GetMemberShopProduct,
        config,
        true,
      )
      if (err) return window.$message.error(err)
      storeProducts.value = res
    }

    // 所有 section 的表單資料與驗證狀態
    const forms = reactive({
      info: {},
      products: {},
    })
    const formsPass = reactive({
      info: false,
      products: false,
    })
    const conditionFormsPass = reactive({})
    const conditionForms = reactive({})

    // 所有表單是否通過驗證
    const allFormPass = computed(() => {
      if (giveawayId.value) {
        // 只檢查 infoForm 因為只有這裡能編輯
        if (formsPass.info) return true
        return false
      }
      const noPass = values(formsPass)
      // const conditionFormsNoPass = values(conditionFormsPass)
      if (noPass.includes(false)) return false
      // if (conditionFormsNoPass.includes(false)) return false
      return true
    })

    // 增加優惠條件區塊
    const addCondition = () => {
      if (Object.keys(conditionForms).length === 5) return
      const formId = nanoid(10)
      set(conditionForms, formId, { gitfs: [], count: 0 })
      set(conditionFormsPass, formId, false)
    }

    const createGiveaway = async (postData) => {
      const [, err] = await CreateMemberShopGiveaway({
        shopId: shopId.value,
        ...postData,
      })
      if (err) throw err
      window.$message.success('新增贈品活動成功！')
    }
    const updateGiveaway = async (postData) => {
      const [, err] = await UpdateMemberShopGiveaway({
        shopId: shopId.value,
        id: giveawayId.value,
        ...postData,
      })
      if (err) throw err
      window.$message.success('更新贈品活動成功！')
    }

    const checkPostData = (data) => {
      if (giveawayId.value) return data
      if (!Object.keys(conditionForms).length) {
        window.$message.warning('請設定優惠條件組合')
        return null
      }
      if (values(conditionFormsPass).includes(false)) {
        window.$message.warning('請設定優惠條件組合')
        return null
      }
      return data
    }
    const onSubmit = async () => {
      formsPass.info = false
      formsPass.products = false
      eventBus.emit('validate-info')
      eventBus.emit('validate-products')
      eventBus.emit('validate-conditions')
    }
    const updateForm = (name, data) => {
      set(forms, name, data)
      // forms[name] = data
    }
    const updateConditionForm = (id, data) => {
      set(conditionForms, id, data)
    }

    const removeConditionBlock = (id) => {
      del(conditionForms, id)
      del(conditionFormsPass, id)
    }

    const findMemberShopGiveaway = async () => {
      const [res, err] = await FindMemberShopGiveaway({
        shopId: shopId.value,
        id: giveawayId.value,
      })
      if (err) return window.$message.error(err)
      giveawayData.value = res
    }

    const syncData = () => {
      const data = giveawayData.value
      if (!data) return
      for (const index in data.conditions) {
        const itemData = data.conditions[index]
        const formId = nanoid(10)
        set(conditionForms, formId, itemData)
      }

      set(forms, 'products', map(data.TargetProducts, 'id'))
    }

    watch(allFormPass, async (pass) => {
      // 當 formPass 所有區塊表單通過驗證時再執行
      if (submitting.value) return
      if (!pass) return
      try {
        submitting.value = true
        await timeout(500)
        const postData = checkPostData(compactPostData())
        if (!postData) {
          submitting.value = false
          return
        }
        if (giveawayId.value) await updateGiveaway(postData)
        else await createGiveaway(postData)

        router.push({
          name: 'MemberShopGiveaway',
        })
      } catch (error) {
        window.$message.error(error.message || error)
        submitting.value = false
      }
    })

    onMounted(async () => {
      eventBus.all.clear()
      loading.value = true
      await getAllStoreProducts()
      if (giveawayId.value) {
        await findMemberShopGiveaway()
        syncData()
      } else {
        addCondition()
      }
      loading.value = false
    })

    onBeforeUnmount(() => {
      if (eventBus) eventBus.all.clear()
    })
    return {
      onSubmit,
      allFormPass,
      formsPass,
      conditionFormsPass,
      updateForm,
      updateConditionForm,
      forms,
      addCondition,
      conditionForms,
      compactPostData,
      storeProducts,
      removeConditionBlock,
      loading,
      giveawayData,
      giveawayId,
      submitting,
    }
  },
})
</script>

<style scoped lang="postcss">
.card-container-padding {
  @apply px-[24px] py-[20px];
}

.member-shop-giveaway-edit {
  @apply flex flex-col gap-[20px];
}
</style>
