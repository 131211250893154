<template>
  <div class="member-shop-giveaway-preview-block">
    <p class="card-title">優惠活動規則預覽</p>
    <div class="conditions-wrapper">
      <div v-for="(condition, index) in postData.conditions" :key="`condition-${index}`" class="condition">
        <p class="condition-title">優惠條件組合{{ index + 1 }}：</p>
        <div class="condition-content">
          購買指定商品
          <span class="text-primary-100">
            滿 [ {{ condition.threshold }} ] 件
          </span>
          可獲得
          <span v-for="(gift, index) in condition.gifts" :key="gift.memberStoreProductId">
            <span class="text-primary-100">[ {{ findProductName(gift.memberStoreProductId) }} ] [ {{ gift.quantity }} ] 件</span>
            <span v-if="index !== condition.gifts.length - 1"> 以及 </span>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { get, find } from 'lodash'

export default defineComponent({
  name: 'MemberShopGiveawayPreviewBlock',
  props: {
    storeProducts: { type: Array, default: () => [] },
    postData: { type: Object, default: () => ({ conditions: [] }) },
  },
  setup (props) {
    const findProductName = (id) => {
      const target = find(props.storeProducts, { id })
      return get(target, 'name')
    }
    return { findProductName }
  },
})
</script>

<style scoped lang="postcss">
.condition-title {
  @apply font-bold mb-[8px];
}

.condition-content span {
  @apply mb-[4px];
}

.conditions-wrapper {
  @apply flex flex-col gap-[20px];
}
</style>
